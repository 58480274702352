import "../../styles/work.scss";
import workInfo from "../../utilities/workInfo";
import { Link } from "react-router-dom";

const Work = () => {
    return (
        <section className="work-section">
            <h2>Our Work</h2>
            <div className="container-work">
                {
                    workInfo.map(el => {
                        return (
                            <>
                                {
                                    el.title === 'Merope Assistant'
                                        ?
                                        <article className='box-work box-workAI' key={el.title} >
                                            <a href={el.link} target="_blank" rel="noopener noreferrer">
                                                <div className="desc-work">
                                                    <h4>{el.title}</h4>
                                                    <p>{el.text}</p>
                                                </div>
                                                <img src={el.img} alt="merope" />
                                            </a>
                                        </article>
                                        :
                                        <article className='box-work' key={el.title} >
                                            <Link to={el.link} target="_blank">
                                                <div className="desc-work">
                                                    <h4>{el.title}</h4>
                                                    <p>{el.text}</p>
                                                </div>
                                                <img src={el.img} alt="merope" />
                                            </Link>
                                        </article>
                                }
                            </>
                        )
                    })
                }
            </div>
        </section>
    );
};

export default Work;