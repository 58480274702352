import Header from "../Header";
import About from "../About";
import Technologies from "../Technologies";
import Contact from "../Contact";
import "../../styles/main.scss";
import Footer from "../Footer";
import Work from "../Work";

const Main = () => {
    return (
        <main>
            <Header />
            <About />
            <Technologies />
            <Work />
            <Contact />
            <Footer />
        </main>
    );
}

export default Main;