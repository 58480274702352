import logo from "../../assets/images/logo.svg";
import "../../styles/header.scss";
import ParticleStars from "../ParticleStars";

const Header = () => {
    return (
        <>
            <ParticleStars />
            <header className="main-header">
                <img className="header-logo" src={logo} alt="logo" />
                <div className="header-text">
                    <p>The sky is the limit? Maybe yes.
                        But you are unlimited in the Metaverse.
                        The next wave of computing and networking is coming.</p>
                </div>
                <div className="scroll-to-explore">scroll to explore</div>
            </header>
        </>
    )
}

export default Header;