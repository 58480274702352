import "../../styles/contact.scss";

const Contact = () => {
    return (
        <section className="contact-section">
            <h2>LET’S WORK TOGETHER</h2>
            <p>Let's discuss projects and ideas.</p>
            <a href="mailto:maciej@merope.pl">maciej@merope.pl</a>
        </section>
    );
};

export default Contact;