import "../../styles/footer.scss";
import { FaFacebook, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer >
            <div className="footer-content">
                <div className="col">
                    <h3>Contact details:</h3>
                    <p>We can meet at google meet or in Warsaw, Białystok, Wrocław.</p>
                    <p><a href="mailto:maciej@merope.pl">maciej@merope.pl</a></p>
                    <p>Privacy policy</p>
                    <p className="license">© 2022 Thoughts, ideas and and suggestions contained in this presentation are owned by Merope company.
                        All materials contained herein are protected by binding copyright and intellectual property laws.
                        Any unauthorised reproduction, distribution or republishing of any parts of its content is strictly prohibited.
                    </p>
                </div>
                <div className="col">
                    <h3>Socials</h3>
                    <a href="https://www.linkedin.com/company/meropepl/" target="_blank" style={{ marginRight: "10px" }} rel="noreferrer">
                        <FaLinkedin size="25px" />
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100088486383505" target="_blank" rel="noreferrer">
                        <FaFacebook size="25px" />
                    </a>
                </div>
            </div>
        </footer >
    )
}

export default Footer;