import React from 'react';
import TechnologiesInfo from "../../utilities/technologiesInfo";
import "../../styles/technologies.scss";

const Technologies = () => {
    return (
        <section className='technologies'>
            <h2>What is the metaverse?</h2>
            <div className='container'>
                <div className='box'>
                    <p>It is a combination of many different technologies.</p>
                    <p>It is a social and virtual world rendered in 3D.</p>
                    <p>It's an addictive space for:</p>

                    <ul>
                        <li>Shops,</li>
                        <li>Virtual Showroom/s,</li>
                        <li>Exhibitions and Tradeshows,</li>
                        <li>Conferences and Events,</li>
                        <li>Workshops & Meetings,</li>
                        <li>Social involvement.</li>
                    </ul>
                </div>
            </div>
            <div className='technologies-wrapper'>
                {
                    TechnologiesInfo.map(el => {
                        return (
                            <>
                                <article className='technology' key={el.title} >
                                    <img src={el.img} alt="merope" />
                                    <h4>{el.title}</h4>
                                    <p>{el.text}</p>
                                </article>
                            </>
                        )
                    })
                }
            </div>
        </section>
    );
};

export default Technologies;