export default [
    {
        title: "Extended Reality Technologies (XR)",
        img: "./images/VR_icon.svg",
        text: "Virtual, augmented, and mixed reality (VR, AR, and MR) is a way to immerse yourself in the metaverse. Key players (including Meta, Microsoft, HTC, and perhaps soon Apple) are actively developing various technologies of extended reality glasses. Increasingly sophisticated and affordable devices will allow you to spend more and more time in the metaverse."

    },
    {
        title: "Cloud Computing",
        img: "./images/Cloud_icon.svg",
        text: "Being in the metaverse will require access to a huge amount of data and computing power. Access to such resources by any user (for example, a person walking down the street) would be uneconomical or unrealistic. Therefore, most of the calculations will be carried out in the cloud on specialized servers, on which applications will also be installed, Metaverse users will only need, for example, a browser installed on a smartphone and broadband connectivity for streaming data.",
    },
    {
        title: "Artificial Intelligence (AI) & Machine Learning",
        img: "./images/ML_icon.svg",
        text: "The development of artificial intelligence and neural networks is already radically changing the way we use the Internet. Data processing using artificial intelligence allows for powerful analytics in real time. The use of artificial intelligence is revolutionizing the way you create and analyze images. The creation of digital twins and avatars using artificial intelligence will lead to the convergence of the physical and virtual world. ",
    },
    {
        title: "IOT technology",
        img: "./images/IoT.svg",
        text: "Internet connectivity keeps getting faster and safer. New technologies such as 5G are pushing the boundaries of the amount of data that can be transferred in parallel and in real time. The huge number of interconnected (mobile) devices / sensors will lead to the convergence of the physical and digital world. IOT will be one of the main drivers of the Metaverse that is available in real time, regardless of time and place.",
    },
    {
        title: "Avatars",
        img: "./images/eye.svg",
        text: "The metaverse is both a 3D and social environment. Therefore, you need to represent yourself to others. The metaverse avatar is essentially a manifestation of the user – it can look like almost anything you can imagine. It might look exactly like you do in the real world. Or your avatar might look different. The avatars can be 2D, flat representations of the user or 3D, having a fully humanoid form that can be viewed from multiple angles. Importantly, it can be customized in almost any way you can imagine. Your skin tone, height, hair, and almost anything else can be changed. And you can purchase unique apparel for your avatar. Many of the most famous brands from the real world offer clothing and accessories in the metaverse in the form of NFTs.",
    },
    {
        title: "Blockchain, Distributed Ledger Technologies (DLT), and Web3",
        img: "./images/BC_icon.svg",
        text: "The development of blockchain, crypto currencies and Web3 is considered a key foundation of the metaverse. While Web1 has focused on content consumption and Web2 on content sharing, Web3 enables the decentralization of digital ownership. This is achieved by removing the need for TTPs (Trusted Third Parties) such as banks. Fungible and non-fungible tokens (NFT) can be stored in distributed ledgers that form the basis of a true digital economy. At the same time, DLT enables interoperability across the metaverse.",
    },
]