import "../../styles/about.scss";

const About = () => {
    return (
        <section className="about">
            <h2>ABOUT US</h2>
            <p>We are a team of artists and highly qualified programmers.</p>
            <p>We design and create practical solutions.</p>
            <p>We are inspired by the most advanced technologies available today.</p>
            <p>Let's talk about projects, ideas and create a unique Metaverse together.</p>
        </section>
    )
}

export default About;