export default [
    {
        title: "Merope Home PL",
        img: "./images/meropehome.jpg",
        text: "Metaversowy system nowej generacji przeznaczony do wizualizacji i konfiguracji nieruchomości oferowanych przez deweloperów.",
        link: "/meropehome"
    },
    {
        title: "Merope Home ENG",
        img: "./images/meropehome1.jpg",
        text: "MeropeHome is a new generation Metaverse system for visualization and configuration of real estate offered by developers.",
        link: "/meropehomeeng"
    },
    {
        title: "Merope Assistant",
        img: "./images/MeropeAssistant-2-Enhanced-SR.jpg",
        text: "MeropeAssistant is a new generation AI Assistant",
        link: "https://assistant.merope.pl/"
    },
]